import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';

import CloudIcon from '!svg-react-loader?name=CloudIcon!../../../assets/icons/cloud.svg';

const What: React.FC<Props> = (props) => {
  const { className } = props;
  return (
    <div
      className={classNames(
        styles.block,
        className
      )}
    >
      <div className={styles.row}>
        <div className={styles.col}>
          <h2 className={styles.label}>
            What it is?
          </h2>

          <p>
            The Data Bakery is a fun and engaging workshop style game
            that quickly creates a common frame of reference, understanding
            of opportunities, needs and challenges linked to data for the
            different parts of a company.
          </p>

          <p>
            This way we merge three important aspects when setting the stage
            for data-driven design and innovation; the user, the business and
            the technological perspective.
          </p>

          <h2 className={styles.label}>
            Why play?
          </h2>

          <ol className={styles.list}>
            <li>
              Unlock the value of data assets.
            </li>
            <li>
              Find your place in a data ecosystem.
            </li>
            <li>
              Develop data-led concepts that are feasible, desirable and viable.
            </li>
            <li>
              Prioritise investments.
            </li>
            <li>
              Enable and boost cross-functional engagement through data literacy.
            </li>
          </ol>
        </div>

        <div className={styles.col}>
          <div className={styles.cloud_container}>
            <CloudIcon className={styles.cloud} />
            <p className={styles.citation}>
              “To share a common perspective between data, design and business people
              is crucial to unlocking the real value of data to an organization”
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default What;