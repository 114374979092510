import React, { useEffect, useRef, useState } from 'react';

import { Props } from './types';
import { isBrowser } from '../../helpers/utils';
import styles from './styles.module.scss';
import Footer from '../../components/Footer';
import Start from '../../components/Start';
import What from '../../components/What';
import Why from '../../components/Why';
import Wrapper from '../../components/Wrapper';

const FrontPage: React.FC<Props> = (_props) => {
  const scrollPosition = useRef(0);
  const scheduledAnimationFrame = useRef(false);
  const breakpointRef = useRef<HTMLElement | null>(null);
  const showRef = useRef(false);
  const [showFooter, setShowFooter] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);

  const readAndUpdatePage = () => {
    scheduledAnimationFrame.current = false;

    if (breakpointRef.current) {
      const show = scrollPosition.current > breakpointRef.current.offsetTop;
      if (showRef.current !== show) {
        showRef.current = show;
        setShowFooter(show);
      }
    }
  };

  const handleScroll = () => {
    scrollPosition.current = window.pageYOffset;

    if (scheduledAnimationFrame.current) {
      return;
    }

    scheduledAnimationFrame.current = true;
    requestAnimationFrame(readAndUpdatePage);
  };

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      if (isBrowser) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className={styles.block}>
      <Wrapper>
        <section
          className={styles.section}
          id="start"
        >
          <Start onContactClick={() => setContactOpen(true)} />
        </section>

        <section
          className={styles.section}
          ref={breakpointRef}
          id="what"
        >
          <What />
        </section>

        <section
          className={styles.section}
          id="why"
        >
          <Why />
        </section>
      </Wrapper>

      <Footer
        expanded={showFooter}
        isOpen={contactOpen}
        onClose={() => setContactOpen(false)}
      />
    </div>
  );
};

export default FrontPage;