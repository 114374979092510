import { FieldError } from './FieldError';
import { ValidationError } from './ValidationError';

/*
  * Collected regex pattern from https://www.w3.org/TR/html5/forms.html#valid-e-mail-address
  * However, with a slight modification for not allowing email@localhost
*/

// tslint:disable-next-line:max-line-length
const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

export class Validators {
  public static email(message?: string) {
    return (value: string): FieldError | null => (
      EMAIL_PATTERN.test(value)
        ? null
        : {
          type: ValidationError.EMAIL,
          text: message || 'Please enter a valid email address'
        }
    );
  }

  public static maxLength(length: number, message?: string) {
    return (value: string): FieldError | null => (
      typeof length === 'number' && length >= 0 && value.length <= length
        ? null
        : {
          type: ValidationError.MAX_LENGTH,
          text: message || `Please enter maximum ${length} characters`
        }
    );
  }

  public static minLength(length: number, message?: string) {
    return (value: string): FieldError | null => (
      typeof length === 'number' && length >= 0 && value.length >= length
        ? null
        : {
          type: ValidationError.MIN_LENGTH,
          text: message || `Please enter minimum ${length} characters`
        }
    );
  }

  public static pattern(pattern: RegExp, message?: string) {
    return (value: string): FieldError | null => (
      pattern instanceof RegExp && pattern.test(value)
        ? null
        : {
          type: ValidationError.PATTERN,
          text: message || 'Invalid format'
        }
    );
  }

  public static required(message?: string) {
    return (value: string | boolean): FieldError | null => (
      !!value
        ? null
        : {
          type: ValidationError.REQUIRED,
          text: message || 'This field is required'
        }
    );
  }
}