import { useEffect, useState } from 'react';
import { InputField } from '../models/InputField';

export const useFormValidation = (fields: { [name: string]: InputField | undefined }) => {
  const [isFormValid, setIsFormValid] = useState(false);

  const isValid = (): boolean => {
    return Object.keys(fields).every((key: string) => {
      const field = fields[key];
      return field && field.validators.every((validator) => validator(field.value) === null);
    });
  };

  useEffect(() => {
    const valid = isValid();
    setIsFormValid(valid);
  }, [fields]);

  return isFormValid;
};