import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';

const Why: React.FC<Props> = (props) => {
  const { className } = props;
  return (
    <div
      className={classNames(
        styles.block,
        className
      )}
    >
      <div className={styles.row}>
        <div className={styles.col_left}>
          <div className={styles.box_row}>
            <div className={styles.box_col}>
              <h2 className={styles.title}>
                Before
              </h2>

              <p className={styles.description}>
                A framework to unpack your current data landscape
              </p>

              <div
                className={styles.image}
                style={{ backgroundImage: 'url("/before.png")' }}
              />

              <ul className={styles.bullet_list}>
                <li>
                  What data initiatives are ongoing?
                </li>
                <li>
                  Where is data currently collected?
                </li>
                <li>
                  How is data currently stored and used?
                </li>
                <li>
                  Who are the main stakeholders when it
                  comes to data initiatives?
                </li>
                <li>
                  What are your strategic goals when it
                  comes to being data-driven?
                </li>
              </ul>
            </div>

            <div className={styles.box_col}>
              <h2 className={styles.title}>
                During
              </h2>

              <p className={styles.description}>
                Lenses through which your data concepts can be validated
              </p>

              <div
                className={styles.image}
                style={{ backgroundImage: 'url("/during.png")' }}
              />

              <span className={styles.subtitle}>
                Desirability – Human lens
              </span>
              <ul className={styles.bullet_list}>
                <li>
                  Who is this for?
                </li>
                <li>
                  Why do they want it?
                </li>
                <li>
                  How does it help them?
                </li>
              </ul>

              <span className={styles.subtitle}>
                Viability – Business lens
              </span>
              <ul className={styles.bullet_list}>
                <li>
                  How does it generate revenue?
                </li>
                <li>
                  How does it save money?
                </li>
              </ul>

              <span className={styles.subtitle}>
                Feasibility – Data lens
              </span>
              <ul className={styles.bullet_list}>
                <li>
                  Is the concept technically possible?
                </li>
              </ul>
            </div>

            <div className={styles.box_col}>
              <h2 className={styles.title}>
                After
              </h2>

              <p className={styles.description}>
                Your recipe for data driven success
              </p>

              <div
                className={styles.image}
                style={{ backgroundImage: 'url("/after.png")' }}
              />

              <ol className={styles.number_list}>
                <li>
                  A shared view, common language and understanding of
                  competing data concepts and how to keep moving towards
                  being a data driven organisation
                </li>
                <li>
                  A validated backlog of data led initiatives prioritised
                  by impact and effort
                </li>
                <li>
                  Detailed requirements and next steps for your key data
                  led initiatives in the form of a recipe card
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div className={styles.col_right}>
          <h2 className={styles.label}>
            What do I get?
          </h2>

          <p>
            After the game you will have a co-created and prioritized list
            that can be used as a step-by-step guide to implementing
            data-led initiatives.
          </p>

          <p>
            You will also have a synchronized and committed team to drive
            and spread ownership, understanding and inspiration regarding
            data-driven design and innovation processes.
          </p>

          <p>
            This will help you create business value through the discovery
            of opportunities, new products and services while breaking
            down barriers between organizational silos.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Why;