import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';
import Button from '../Button';

import ChevronIcon from '!svg-react-loader?name=ChevronIcon!../../../assets/icons/chevron.svg';
import DownloadIcon from '!svg-react-loader?name=DownloadIcon!../../../assets/icons/Download.svg';
import MailIcon from '!svg-react-loader?name=MailIcon!../../../assets/icons/mail.svg';

const Start: React.FC<Props> = (props) => {
  const { className, onContactClick } = props;

  const downloadPDF = () => {
    const link = document.createElement('a');
    link.href = 'data_bakery_guide_2021.pdf';
    link.download = 'data_bakery_guide_2021.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  };

  return (
    <div
      className={classNames(
        styles.block,
        className
      )}
    >
      <div className={styles.row}>
        <div className={styles.left_col}>
          <h1 className={styles.title}>
            Kickstart your data-driven organization.
          </h1>
        </div>

        <div className={styles.right_col}>
          <p>
            Data is one of the most important assets for organizations today,
            but many still find it difficult to take advantage of the opportunities
            offered by the data revolution.
          </p>

          <p>
            That’s why the Data Bakery was created. With it, you’ll be able to
            kickstart your data-driven organization.
          </p>

          <div className={styles.button_row}>
            <Button
              className={styles.button}
              modifiers="primary small rounded no-text"
              onClick={onContactClick}
              title="Contact us"
            >
              <MailIcon className={styles.icon} />
              <span className="text">
                Contact us
              </span>
            </Button>

            <Button
              className={styles.button}
              modifiers="secondary small rounded no-text"
              title="Learn more"
              to="#what"
            >
              <ChevronIcon className={styles.icon} />
              <span className="text">
                Learn more
              </span>
            </Button>

            <Button
              className={styles.button}
              modifiers="secondary small rounded no-text"
              onClick={downloadPDF}
              title="Download baking guide"
            >
              <DownloadIcon className={styles.download_icon} />
              <span className="text">
                Baking guide
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Start;