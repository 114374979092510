import { useState } from 'react';

export const useFieldState = <T>(_fields: T): [T, (name: string, value: any) => void] => {
  const [fields, setFields] = useState<T>(_fields);

  const setter = (name: string, value: any) => {
    setFields({
      ...fields,
      [name]: {
        ...fields[name],
        value
      }
    });
  };

  return [fields, setter];
};