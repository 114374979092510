import React, { useState } from 'react';
import classNames from 'classnames';

import { Props } from './types';
import { http } from '../../helpers/utils';
import { useFadeOut } from '../../hooks/fadeOut';
import { useFieldState } from '../../hooks/fieldState';
import { useFormValidation } from '../../hooks/formValidation';
import { Validators } from '../../models/Validators';
import styles from './styles.module.scss';
import Button from '../Button';
import Input from '../Input';
import Wrapper from '../Wrapper';

import CheckboxIcon from '!svg-react-loader?name=CheckboxIcon!../../../assets/icons/checksmall.svg';
import CloseIcon from '!svg-react-loader?name=CloseIcon!../../../assets/icons/close.svg';
import MailIcon from '!svg-react-loader?name=MailIcon!../../../assets/icons/mail.svg';

export const ContactModal: React.FC<Props> = (props) => {
  const { animationDuration, className, onClose, show } = props;
  const duration = (animationDuration || 0) + 'ms';
  const shouldFadeOut = useFadeOut(show);
  const [fail, setIsFail] = useState(false);
  const [success, setIsSuccess] = useState(false);
  const [fields, setFields] = useFieldState({
    firstname: {
      value: '',
      validators: [Validators.required()]
    },
    email: {
      value: '',
      validators: [Validators.required(), Validators.email()]
    }
  });
  const isValid = useFormValidation(fields);

  const onChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    setFields(name, value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isValid && !success && !fail) {
      http('/api/v1/contact',
        {
          method: 'POST',
          body: JSON.stringify({
            firstname: fields.firstname.value,
            email: fields.email.value
          })
        })
        .then(() => {
          setIsSuccess(true);
          setTimeout(() => {
            onClose();
          }, 3000);
        })
        .catch(() => setIsFail(true));
    }
  };

  return (
    <aside
      arial-modal="true"
      className={classNames(
        styles.block,
        {
          [styles.show]: show,
          [styles.fade_out]: !show && shouldFadeOut
        },
        className
      )}
      role="dialog"
      style={{
        transitionDuration: duration
      }}
      tabIndex={-1}
    >
      <Wrapper className={styles.wrapper}>
        <button
          className={styles.fake_button}
          onClick={onClose}
          title="Close"
        />

        <div className={styles.top}>
          <h1 className={styles.title}>
            We’d love to hear from you!
          </h1>

          <Button
            className={styles.close_button}
            modifiers="secondary clear small no-text no-margin no-padding-x"
            onClick={onClose}
            title="Close"
          >
            <span className="text">
              Close
            </span>
            <CloseIcon className={styles.icon} />
          </Button>
        </div>

        <div className={styles.row}>
          <div className={styles.left}>
            <p className={styles.label}>
              Send us your info
            </p>

            <form
              className={styles.form}
              onSubmit={onSubmit}
            >
              <Input
                autoComplete="off"
                className={styles.input}
                floatable={false}
                maxLength={64}
                name="firstname"
                onChange={onChange}
                placeholder="First name"
                type="text"
                validators={fields.firstname.validators}
                value={fields.firstname.value}
              />

              <Input
                autoComplete="off"
                className={styles.input}
                floatable={false}
                maxLength={64}
                name="email"
                onChange={onChange}
                placeholder="Email"
                type="email"
                validators={fields.email.validators}
                value={fields.email.value}
              />

              <Button
                className={styles.button}
                modifiers="secondary small rounded no-text"
                title="Send"
                type="submit"
              >
                {!success &&
                  <>
                    <MailIcon className={styles.icon} />
                    <span className="text">
                      Send
                    </span>
                  </>
                }

                {success &&
                  <>
                    <CheckboxIcon className={styles.icon} />
                    <span className="text">
                      Thank you!
                    </span>
                  </>
                }
              </Button>
            </form>
          </div>

          <div className={styles.right}>
            <p
              className={classNames(
                styles.label,
                styles.contact
              )}
            >
              Or contact Anna
            </p>

            <div className={styles.contact_row}>
              <img
                alt="Anna"
                className={styles.avatar}
                src="anna.jpg"
              />

              <div>
                <p>
                  <span>Mail: </span>
                  <a href="mailto:anna.hullert@smartr.se">
                    anna.hullert@smartr.se
                  </a>
                </p>

                <p>
                  <span>or phone: </span>
                  <a href="tel:+46730481952">
                    +46 730 48 19 52
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </aside>
  );
};