import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';
import ContactModal from '../ContactModal';
import Button from '../Button';
import Wrapper from '../Wrapper';

import MailIcon from '!svg-react-loader?name=MailIcon!../../../assets/icons/mail.svg';

const Footer: React.FC<Props> = (props) => {
  const { className, expanded, onClose } = props;
  const [isOpen, setIsOpen] = useState(false);
  const footerRef = useRef<HTMLDivElement | null>(null);

  const close = () => {
    setIsOpen(false);
    onClose();
  };

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <footer
      className={classNames(
        styles.footer,
        className,
        {
          [styles.open]: isOpen,
          [styles.expanded]: expanded
        }
      )}
      ref={footerRef}
    >
      <Wrapper
        className={classNames(
          styles.content,
          { [styles.open]: isOpen }
        )}
      >
        <Button
          className={styles.button}
          modifiers="secondary small rounded no-text"
          onClick={() => setIsOpen(true)}
          title="Contact us"
        >
          <MailIcon className={styles.mail} />
          <span className="text">
            Contact us
          </span>
        </Button>

        <p className={styles.text}>
          <b>The Data Bakery</b> was created by The Service Design Studio and Smartr in Gothenburg.
          <br />
          Our workshop concepts and games have been played with over 1000 people
          across 5 countries in multiple sectors.
        </p>
      </Wrapper>

      <ContactModal
        animationDuration={150}
        closeOnBackdropClick={true}
        container={footerRef.current}
        isOpen={isOpen}
        onClose={close}
      />
    </footer>
  );
};

export default Footer;