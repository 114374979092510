import React from 'react';

import { ContainerProps } from './types';
import { ContactModal } from './component';
import Modal from '../Modal';

const Container: React.FC<ContainerProps> = (props) => (
  <Modal
    component={ContactModal}
    {...props}
  />
);

export default Container;